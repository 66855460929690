var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$root.$data.portalInfo.code != "thso" &&
    _vm.$root.$data.me.access.ST1
    ? _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c("page-snippet", { attrs: { name: "royalties" } }),
                  _c(
                    "b-card",
                    [
                      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                        _vm._v(" Filters / Options ")
                      ]),
                      _c(
                        "b-container",
                        { staticClass: "p-0", attrs: { fluid: "" } },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    cols: "12",
                                    md: "6",
                                    lg: "6",
                                    xl: "6"
                                  }
                                },
                                [
                                  _vm._v(" Statement Reference "),
                                  _c("ApiLookup", {
                                    attrs: {
                                      source: "analytics/find-statement-refs",
                                      filter: _vm.filter
                                    },
                                    model: {
                                      value: _vm.filter.selectedRefs,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.filter,
                                          "selectedRefs",
                                          $$v
                                        )
                                      },
                                      expression: "filter.selectedRefs"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    cols: "12",
                                    md: "6",
                                    lg: "6",
                                    xl: "6"
                                  }
                                },
                                [
                                  _c("div", { staticClass: "mr-2" }, [
                                    _vm._v("Period:")
                                  ]),
                                  _c(
                                    "span",
                                    { staticClass: "mt-2" },
                                    [
                                      _c("b-form-select", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          options: _vm.apOptions,
                                          size: "sm"
                                        },
                                        model: {
                                          value: _vm.filter.apFrom,
                                          callback: function($$v) {
                                            _vm.$set(_vm.filter, "apFrom", $$v)
                                          },
                                          expression: "filter.apFrom"
                                        }
                                      }),
                                      _vm._v(" - "),
                                      _c("b-form-select", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          options: _vm.apOptions,
                                          size: "sm"
                                        },
                                        model: {
                                          value: _vm.filter.apTo,
                                          callback: function($$v) {
                                            _vm.$set(_vm.filter, "apTo", $$v)
                                          },
                                          expression: "filter.apTo"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    cols: "12",
                                    md: "6",
                                    lg: "6",
                                    xl: "6"
                                  }
                                },
                                [
                                  _c("div", { staticClass: "mr-2" }, [
                                    _vm._v("Results:")
                                  ]),
                                  _c(
                                    "b-button-group",
                                    { staticClass: "mr-2" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          class: { active: _vm.perPage == 5 },
                                          on: {
                                            click: function($event) {
                                              _vm.perPage = 5
                                            }
                                          }
                                        },
                                        [_vm._v(" 5 ")]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          class: { active: _vm.perPage == 10 },
                                          on: {
                                            click: function($event) {
                                              _vm.perPage = 10
                                            }
                                          }
                                        },
                                        [_vm._v(" 10 ")]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          class: { active: _vm.perPage == 25 },
                                          on: {
                                            click: function($event) {
                                              _vm.perPage = 25
                                            }
                                          }
                                        },
                                        [_vm._v(" 25 ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    cols: "12",
                                    md: "6",
                                    lg: "6",
                                    xl: "6"
                                  }
                                },
                                [
                                  _vm._v(" Number format:"),
                                  _c("br"),
                                  _c(
                                    "b-button-group",
                                    { staticClass: "mr-2" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          class: {
                                            active: _vm.numberLocale == "intl"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.numberLocale = "intl"
                                            }
                                          }
                                        },
                                        [_vm._v(" 1 000.00 ")]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          class: {
                                            active: _vm.numberLocale == "en"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.numberLocale = "en"
                                            }
                                          }
                                        },
                                        [_vm._v(" 1,000.00 ")]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          class: {
                                            active: _vm.numberLocale == "nl"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.numberLocale = "nl"
                                            }
                                          }
                                        },
                                        [_vm._v(" 1.000,00 ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "mt-3",
                                  attrs: {
                                    cols: "12",
                                    md: "6",
                                    lg: "6",
                                    xl: "6"
                                  }
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { variant: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.refresh()
                                        }
                                      }
                                    },
                                    [_vm._v("Apply")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "secondary" },
                                      on: { click: _vm.clearFilters }
                                    },
                                    [_vm._v("Reset")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    cols: "12",
                                    md: "6",
                                    lg: "6",
                                    xl: "6"
                                  }
                                },
                                [
                                  _c("span", { staticClass: "mr-2" }, [
                                    _vm._v("Export type:")
                                  ]),
                                  _c("br"),
                                  _c(
                                    "b-button-group",
                                    { staticClass: "mr-4" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          class: {
                                            active: _vm.exportType == "xlsx"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.exportType = "xlsx"
                                            }
                                          }
                                        },
                                        [_vm._v(" Excel ")]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          class: {
                                            active: _vm.exportType == "csv"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.exportType = "csv"
                                            }
                                          }
                                        },
                                        [_vm._v(" CSV ")]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          class: {
                                            active: _vm.exportType == "tab"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.exportType = "tab"
                                            }
                                          }
                                        },
                                        [_vm._v(" TAB ")]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          class: {
                                            active: _vm.exportType == "html"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.exportType = "html"
                                            }
                                          }
                                        },
                                        [_vm._v(" HTML ")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "span",
                                    { attrs: { id: "exportWrapper" } },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            variant: "primary",
                                            disabled:
                                              _vm.exportBusy ||
                                              _vm.filterChanged
                                          },
                                          on: { click: _vm.exportTable }
                                        },
                                        [
                                          !_vm.filterChanged
                                            ? [_vm._v("Export")]
                                            : _vm._e(),
                                          _vm.filterChanged
                                            ? [_vm._v("Apply filter to export")]
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  ),
                                  _c("b-spinner", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.exportBusy,
                                        expression: "exportBusy"
                                      }
                                    ],
                                    attrs: { small: "", label: "Spinning" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-4" },
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-card",
                    [
                      _c(
                        "div",
                        { attrs: { slot: "header" }, slot: "header" },
                        [
                          _vm._v(
                            " Labels - " +
                              _vm._s((_vm.currentPage - 1) * _vm.perPage + 1) +
                              " - " +
                              _vm._s(_vm.currentPage * _vm.perPage) +
                              " of " +
                              _vm._s(_vm.totalRows) +
                              " "
                          ),
                          _c("b-spinner", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.loading,
                                expression: "loading"
                              }
                            ],
                            attrs: { small: "", label: "Spinning" }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.totalRows == 0,
                              expression: "totalRows == 0"
                            }
                          ]
                        },
                        [_vm._v(" No data available ")]
                      ),
                      _c(
                        "b-container",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.totalRows > 0,
                              expression: "totalRows > 0"
                            }
                          ],
                          staticClass: "p-0",
                          attrs: { fluid: "" }
                        },
                        [
                          _c(
                            "b-row",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.totalRows > _vm.perPage,
                                  expression: "totalRows > perPage"
                                }
                              ]
                            },
                            [
                              _c(
                                "b-col",
                                [
                                  _c("b-pagination", {
                                    attrs: {
                                      "total-rows": _vm.totalRows,
                                      "per-page": _vm.perPage,
                                      align: "fill",
                                      size: "sm"
                                    },
                                    model: {
                                      value: _vm.currentPage,
                                      callback: function($$v) {
                                        _vm.currentPage = $$v
                                      },
                                      expression: "currentPage"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("b-table", {
                                    attrs: {
                                      small: "",
                                      striped: "",
                                      hover: "",
                                      id: "royalty-transaction-table",
                                      items: _vm.provider,
                                      fields: _vm.fields,
                                      "current-page": _vm.currentPage,
                                      "per-page": _vm.perPage
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "head()",
                                          fn: function(data) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.fieldLabels[data.column]
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "head(currency)",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "text-right" },
                                                [_vm._v("Currency")]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        },
                                        {
                                          key: "head(quantity)",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "text-right" },
                                                [_vm._v("Quantity")]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        },
                                        {
                                          key: "head(grossIncome)",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "text-right" },
                                                [_vm._v("Gross Income")]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        },
                                        {
                                          key: "head(providerIncome)",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "text-right" },
                                                [
                                                  _vm._v(
                                                    "Provider Share Royalty"
                                                  )
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        },
                                        {
                                          key: "cell(reference)",
                                          fn: function(data) {
                                            return [
                                              _c(
                                                "a",
                                                {
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function($event) {
                                                      $event.preventDefault()
                                                      return _vm.filterItem({
                                                        type: "reference",
                                                        item: {
                                                          key:
                                                            data.item.reference,
                                                          title:
                                                            data.item.reference
                                                        }
                                                      })
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        data.item.reference
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "cell(accountingPeriod)",
                                          fn: function(data) {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "text-nowrap" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f(
                                                        "formatAccountingPeriod"
                                                      )(
                                                        data.item
                                                          .accountingPeriod
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "cell(currency)",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "text-right" },
                                                [_vm._v("EUR")]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        },
                                        {
                                          key: "cell(quantity)",
                                          fn: function(data) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "text-right",
                                                  staticStyle: {
                                                    "font-family":
                                                      "'Roboto Mono', monospace"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("numberFormat")(
                                                        data.item.quantity,
                                                        0,
                                                        _vm.numberLocale
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "cell(grossIncome)",
                                          fn: function(data) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "text-right",
                                                  staticStyle: {
                                                    "font-family":
                                                      "'Roboto Mono', monospace"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("numberFormat")(
                                                        data.item.grossIncome,
                                                        2,
                                                        _vm.numberLocale
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "cell(providerIncome)",
                                          fn: function(data) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "text-right",
                                                  staticStyle: {
                                                    "font-family":
                                                      "'Roboto Mono', monospace"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("numberFormat")(
                                                        data.item
                                                          .providerIncome,
                                                        2,
                                                        _vm.numberLocale
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1780391908
                                    )
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }