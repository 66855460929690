<template>
  <b-container fluid v-if="($root.$data.portalInfo.code != 'thso') && $root.$data.me.access.ST1">
    <b-row>
      <b-col>
        <page-snippet name="royalties" />

        <b-card>
          <div slot="header">
            Filters / Options
          </div>

          <b-container fluid class="p-0" >
            <b-row>
              <b-col cols="12" md="6" lg="6" xl="6" class="mb-4">
                Statement Reference
                <ApiLookup v-model="filter.selectedRefs" source="analytics/find-statement-refs" :filter="filter"  />
              </b-col>

              <b-col cols="12" md="6" lg="6" xl="6" class="mb-4">
                <div class="mr-2">Period:</div>

                <span class="mt-2">
                  <b-form-select v-model="filter.apFrom" :options="apOptions" size="sm" class="mr-2"></b-form-select>
                  - 
                  <b-form-select v-model="filter.apTo" :options="apOptions" size="sm" class="mr-2"></b-form-select>
                </span>
              </b-col>

              <b-col cols="12" md="6" lg="6" xl="6" class="mb-4">
                <div class="mr-2">Results:</div>

                <b-button-group class="mr-2">
                  <b-button
                      v-on:click="perPage = 5"
                      v-bind:class="{ active: perPage == 5 }">
                    5
                  </b-button>
                  <b-button
                      v-on:click="perPage = 10"
                      v-bind:class="{ active: perPage == 10 }">
                    10
                  </b-button>
                  <b-button
                      v-on:click="perPage = 25"
                      v-bind:class="{ active: perPage == 25 }">
                    25
                  </b-button>
                </b-button-group>
              </b-col>

              <b-col cols="12" md="6" lg="6" xl="6" class="mb-4">
                Number format:<br>
                <b-button-group class="mr-2">
                  <b-button
                      v-on:click="numberLocale = 'intl'"
                      v-bind:class="{ active: numberLocale == 'intl' }">
                    1 000.00
                  </b-button>
                  <b-button
                      v-on:click="numberLocale = 'en'"
                      v-bind:class="{ active: numberLocale == 'en' }">
                    1,000.00
                  </b-button>
                  <b-button
                      v-on:click="numberLocale = 'nl'"
                      v-bind:class="{ active: numberLocale == 'nl' }">
                    1.000,00
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6" lg="6" xl="6" class="mt-3">
                <b-button variant="primary" class="mr-2" v-on:click="refresh()">Apply</b-button>
                <b-button variant="secondary" v-on:click="clearFilters">Reset</b-button>
              </b-col>

              <b-col cols="12" md="6" lg="6" xl="6" class="mb-4">
                <span class="mr-2">Export type:</span><br>
                <b-button-group class="mr-4">
                  <b-button
                      v-on:click="exportType = 'xlsx'"
                      v-bind:class="{ active: exportType == 'xlsx' }">
                    Excel
                  </b-button>
                  <b-button
                      v-on:click="exportType = 'csv'"
                      v-bind:class="{ active: exportType == 'csv' }">
                    CSV
                  </b-button>
                  <b-button
                      v-on:click="exportType = 'tab'"
                      v-bind:class="{ active: exportType == 'tab' }">
                    TAB
                  </b-button>
                  <b-button
                      v-on:click="exportType = 'html'"
                      v-bind:class="{ active: exportType == 'html' }">
                    HTML
                  </b-button>
                </b-button-group>

                <span id="exportWrapper">
                  <b-button variant="primary" v-on:click="exportTable" :disabled="exportBusy || filterChanged">
                    <template v-if="!filterChanged">Export</template>
                    <template v-if="filterChanged">Apply filter to export</template>
                  </b-button>
                </span>

                <b-spinner small v-show="exportBusy" label="Spinning"></b-spinner>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-4">
      <b-col>
        <b-card>
          <div slot="header">
            Labels -
            {{ ((currentPage - 1) * perPage) + 1 }} - {{ ((currentPage) * perPage) }} of {{ totalRows }}

            <b-spinner small v-show="loading" label="Spinning"></b-spinner>
          </div>
          
          <div v-show="totalRows == 0">
            No data available
          </div>

          <b-container fluid class="p-0" v-show="totalRows > 0">
            <b-row v-show="totalRows > perPage">
              <b-col>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"></b-pagination>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-table 
                    small
                    striped
                    hover
                    id="royalty-transaction-table"
                    :items="provider"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage">

                  <template v-slot:head()="data">
                    {{ fieldLabels[data.column] }}
                  </template>

                  <template v-slot:head(currency)="">
                    <div class="text-right">Currency</div>
                  </template>

                  <template v-slot:head(quantity)="">
                    <div class="text-right">Quantity</div>
                  </template>

                  <template v-slot:head(grossIncome)="">
                    <div class="text-right">Gross Income</div>
                  </template>

                  <template v-slot:head(providerIncome)="">
                    <div class="text-right">Provider Share Royalty</div>
                  </template>

                  <template v-slot:cell(reference)="data">
                    <a href="#" @click.prevent="filterItem({type: 'reference', item: { key: data.item.reference, title: data.item.reference }})">
                      {{ data.item.reference }}
                    </a>
                  </template>

                  <template v-slot:cell(accountingPeriod)="data">
                    <div class="text-nowrap">{{ data.item.accountingPeriod | formatAccountingPeriod }}</div>
                  </template>
                  
                  <template v-slot:cell(currency)="">
                    <div class="text-right">EUR</div>
                  </template>

                  <template v-slot:cell(quantity)="data">
                    <div class="text-right" style="font-family: 'Roboto Mono', monospace;">{{ data.item.quantity | numberFormat(0, numberLocale) }}</div>
                  </template>
                  
                  <template v-slot:cell(grossIncome)="data">
                    <div class="text-right" style="font-family: 'Roboto Mono', monospace;">{{ data.item.grossIncome | numberFormat(2, numberLocale) }}</div>
                  </template>

                  <template v-slot:cell(providerIncome)="data">
                    <div class="text-right" style="font-family: 'Roboto Mono', monospace;">{{ data.item.providerIncome | numberFormat(2, numberLocale) }}</div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import axios from 'axios'
  import apiMixin from '@/mixins/RoyaltyApi'

  export default {
    name: 'RoyaltyAccountingPortal',
    mixins: [apiMixin],
    components: {
        ApiLookup: () => import('@/components/ApiLookup.vue'),
        'page-snippet': () => import('@/components/PageSnippet.vue')
    },
    data () {
      return {
        filterSerial: 1,
        sortDirection: 'desc',
        filterChanged: false,
        filter: {
          apFrom: '',
          apTo: '',
          selectedRefs: [],
        },
        trendsLoading: false,
        target: 'ProviderIncome',
        perPage: 5,
        apOptions: [],
        numberLocale: 'nl',
        view: '',

        loading: false,
        configureColumnsVisible: false,
        activePerPage: 5,
        cancelToken: null,
        exportType: 'xlsx',
        exportColumns: 'selected',
        exportBusy: false,
        currentPage: 1,
        totalRows: 0,
        orderBy: 'selected',      
        fields: [
          'reference',
          'accountingPeriod',
          'currency',
          'grossIncome',
          'providerIncome',
        ],
        fieldLabels: {
          reference: 'Reference',
          track: 'Track', 
          album: 'Album',
          attribution: 'Attribution',
          territory: 'Country',
          outlet: 'Outlet',
          accountingPeriod: 'Accounting Period',
          activityPeriod: 'Activity Period',
          exploitationFormat: 'Format',
          transactionType: 'Transaction Type',
          productIdentifier: 'ISRC',
          albumIdentifier: 'Barcode',
          exceptionDetails: 'Exception details',
          exceptionQuantity: 'Exception quantity',
        },
      }
    },
    props: {
      inputParams: Object
    },
    watch: {
      filter: {
        handler () {
          this.filterChanged = true
        },
        deep: true
      }
    },
    methods: {
      filterItem (data) {
        let item = data.item
        if (data.type == 'reference') {
          this.filter.selectedRefs.push({key: item.key, value: item.title})
        } else if (data.type == 'accountingPeriod') {
          this.filter.apFrom = item.key
          this.filter.apTo = item.key
        }
      },
      clearFilters () {
        this.clearArray(this.filter.selectedRefs)
      },
      clearArray(arr) {
        while (arr.length > 0)
          arr.pop()
      },
      exportTable () {
        // Construct filename
        let filename = 'RoyaltyStatement_' + 
                        this.filter.apFrom
        if (this.filter.apFrom != this.filter.apTo)
          filename += '_' + this.filter.apTo

        //if (this.$root.$data.me.Type != 'admin')
        //  filename += '_' + this.$root.$data.me.ContractID

        let refs = this.filter.selectedRefs   || []
        if (refs.length > 0) {
          if (refs.length >= 10) {
            filename += '_multiple-statements'
          } else {
            filename += '_' + refs.map(r => r.key).join('-')
          }
        }

        let params = {
          exportFileType: this.exportType,
          exportColumns: 'all',
          filter: this.filter,
          numberLocale: this.numberLocale,
          filename: filename,
        }

        // Start export
        this.exportBusy = true
        this.$http
          .post('analytics/royalty-transactions', params)
          .then((result) => {
            let jobId = result.data.jobId
            setTimeout(() => {
              this.$http.get('users/me/jobs/' + jobId)
                .then(response => {
                  if (response.data.status == 'done') {
                    let link = document.createElement('a')
                    link.href = '/api/users/me/export-files/' + jobId
                    document.body.appendChild(link)
                    link.click()
                  } else {
                    this.$bvModal.msgBoxOk('Export gestart, bekijk het menu-item "Exports" om te downloaden.')
                  }
                  this.exportBusy = false
                }).catch(() => {
                  this.$bvModal.msgBoxOk('Er ging helaas iets mis tijdens de export. Probeer het nogmaals.')
                  this.exportBusy = false
                });
            }, 2000)
          }).catch(() => {
            this.$bvModal.msgBoxOk('Er ging helaas iets mis tijdens de export. Probeer het nogmaals.')
            this.exportBusy = false
          })
      },
      refresh () {
        if (this.cancelToken) {
          this.cancelToken.cancel()
          this.cancelToken = null
        }
        this.$root.$emit('bv::refresh::table', 'royalty-transaction-table')
      },
      provider (ctx) {
        if ((this.filter.apFrom == null) || (this.filter.apTo == null)) return []

        return new Promise((resolve) => {
          if (this.cancelToken) {
            this.cancelToken.cancel()
          }
          this.cancelToken = axios.CancelToken.source()

          let fields = this.fields.filter(f => f != 'currency')

          ctx['fields'] = fields
          ctx['filter'] = this.filter

          if (this.orderBy != 'selected')
            ctx['orderBy'] = [this.orderBy + ' ' + this.sortDirection.toUpperCase()];
          else
            ctx['orderBy'] = []

          this.loading = true
          this.$http
              .post('analytics/royalty-transactions', ctx, { cancelToken: this.cancelToken.token })
              .then(response => {
                  this.waitForJob(response)
                    .then(data => {
                      this.totalRows = Number(data.count)
                      this.loading = false
                      
                      this.filterChanged = false
                      resolve(data.rows)
                    })
                    .catch(() => {
                      this.error = "Error occurred, please try again."
                      resolve([])
                    })

              }).catch(function () {
                  resolve([])
              })
        })
      },
    },
    computed: {
    },
    mounted () {
      this.$root.$data.crumbs = [
        {to: { name: 'royalty-accounting' }, text: 'Royalty accounting'}
      ]

      let params = this.$route.query

      if (params.statementRefs) {
        params.statementRefs.split(',').forEach(item => {
          this.filter.selectedRefs.push({
            key: item,
            value: item
          })
        })
      }
      
      this.$http
        .get('analytics/accounting-periods')
        .then(response => {
          let result = response.data
          this.apOptions = []
          result.forEach(item => {
            this.apOptions.push({
              value: item.id,
              text: item.description
            })
          })
          
          if (params.accountingPeriod) {
            this.filter.apFrom = params.accountingPeriod
            this.filter.apTo = params.accountingPeriod
          } else {
            this.filter.apFrom = this.apOptions[this.apOptions.length-1].value
            this.filter.apTo = this.filter.apFrom
          }
          this.refresh()
        })
    }
  }
</script>

<style scoped>
  .btn { white-space: nowrap; }

  .custom-select {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
</style>
